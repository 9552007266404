//创建原型分组列表
export const createProtoGroupListCols = [
  {
    prop: 'chineseName',
    label: '原型名称'
  },
  {
    prop: 'productCategoryName',
    label: '原型分类',
    slotName: 'productCategoryNameSlot'
  },
  {
    prop: 'styleList',
    label: '款式',
    minWidth: '180',
    slotName: 'styleListSlot'
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: '200',
    slotName: 'sizeListSlot'
  }
]

//创建图片分组列表
export const createPublicImageGroupListCols = [
  {
    prop: 'title',
    label: '图片名称'
  },
  {
    prop: 'merge',
    label: '图片',
    minWidth: '150',
    slotName: 'mergeSlot'
  },
  {
    prop: 'categoryName',
    label: '图片分类'
  }
]
